<template lang='pug'>
form.form-finalizar(@submit.prevent='handleSubmit()')
    ProgressBar(v-if='waitingForm' mode="indeterminate")
    div(v-else-if="statusInicial!='P'")
        Panel(header="Finalizar")
            .p-grid.p-fluid.p-align-end
                .p-col-12.p-md-12
                    label.form-label Descrição:
                    InputText(v-model='$v.model.ds_pagamento.$model')
                    .feedback--errors(v-if='submitted && $v.model.ds_pagamento.$error')
                        .form-message--error(v-if="!$v.model.ds_pagamento.required") <b>Descrição do Pagamento</b> é obrigatório.

                .p-col-12.p-md-4
                    label.form-label Estabelecimento:
                    InputText(v-model='model.nm_estabelecimento' :readonly='true')

                .p-col-6.p-md-4
                    label.form-label Data início:
                    InputText(v-model='model.dt_inicial_f' :readonly='true')

                .p-col-6.p-md-4
                    label.form-label Data fim:
                    InputText(v-model='model.dt_final_f' :readonly='true')

                .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ie_status_pagamento.$error }")
                    label.form-label Status Pagamento:
                    Dropdown(v-model='$v.model.ie_status_pagamento.$model' :options='options.statusPagamentos' dataKey='status'
                        optionLabel='nameStatus' optionValue='status' placeholder='Selecione' :filter='options.statusPagamentos.length > 0')
                    .feedback--errors(v-if='submitted && $v.model.ie_status_pagamento.$error')
                        .form-message--error(v-if="!$v.model.ie_status_pagamento.required") <b>Status do Pagamento</b> é obrigatório.

                .p-col-6.p-md-4(:class="{'form-group--error': submitted && $v.model.dt_competencia.$error }")
                    label.form-label Data de competência:
                    .p-inputgroup
                        Calendar( v-model='$v.model.dt_competencia.$model' dateFormat="mm/yy" :locale="$parent.ptbr" :manualInput='false'
                            :yearRange="`2020:${proximoAno}`" :yearNavigator='true' view='month')
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='model.dt_competencia = null')
                    .feedback--errors(v-if='submitted && $v.model.dt_competencia.$error')
                        .form-message--error(v-if="!$v.model.dt_competencia.required") <b>Data de competência</b> é obrigatório.

                .p-col-6.p-md-4(:class="{'form-group--error': submitted && $v.model.dt_pagamento.$error }")
                    label.form-label Data de pagamento:
                    Calendar(
                        v-if="$v.model.ie_status_pagamento.$model == 'P' && model.dt_pagamento"
                        v-model="$v.model.dt_pagamento.$model"
                        dateFormat="dd/mm/yy"
                        :locale="$parent.ptbr"
                        :manualInput='false'
                        )
                    Calendar(v-else-if="$v.model.ie_status_pagamento.$model != 'P' && !model.dt_pagamento"
                            v-model="model.dt_hoje"
                            dateFormat="dd/mm/yy"
                            :locale="$parent.ptbr"
                            :manualInput='false'
                        )
                    Calendar(v-else
                            v-model=null
                            dateFormat="dd/mm/yy"
                            :locale="$parent.ptbr"
                            :manualInput='false'
                        )
                    .feedback--errors(v-if='submitted && $v.model.dt_pagamento.$error')
                        .form-message--error(v-if="!$v.model.dt_pagamento.required") <b>Data de pagamento</b> é obrigatório.
               
                .p-col-6.p-md-4
                    label.form-label Valor bruto:
                    InputText(v-model='model.nr_valorbruto_f' :readonly='true')

                .p-col-6.p-md-4
                    label.form-label Desconto INSS:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        span(style='flex:1')
                            currency-input.input-nr_descontoinss.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                v-model='model.nr_descontoinss'
                                locale='pt-BR'
                                :auto-decimal-mode='true'
                                :value-range='{ min: 0 }')

                .p-col-6.p-md-4
                    label.form-label Imposto federal:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        span(style='flex:1')
                            currency-input.input-nr_impostofederal.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                v-model='model.nr_impostofederal'
                                locale='pt-BR'
                                :auto-decimal-mode='true'
                                :value-range='{ min: 0 }')

                .p-col-12.mt-2.mb-2(v-if="model.id")
                    .p-grid
                        .p-col-12.p-md-6.p-lg-3
                            span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                        .p-col-12.p-md-6.p-lg-3
                            span <b>Data da criação: </b> {{ model.dt_criado_f }}
                        .p-col-12.p-md-6.p-lg-3
                            span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                        .p-col-12.p-md-6.p-lg-3
                            span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                .p-col-12(v-if="this.model.guias_processadas.length > 0")
                    DataTable(:value="this.model.guias_processadas" responsiveLayout="scroll")
                        Column(field='nm_especialista' header='Especialista')
                        Column(field='valor_repasse' header='Valor Repasse' headerStyle='width: 10em')
                        Column(field='valor_total' header='Valor Total' headerStyle='width: 10em')

                .p-col-6
                    a(@click='$parent.finalizar = false' style='cursor:pointer') Visualizar guias

                //.p-col-6(v-else)
                    ProgressSpinner.waitingRelatorio(v-if='waitingRelatorio' strokeWidth='2' style="width:30px;")
                    a(v-else @click='gerarRelatorio()' style='cursor:pointer') Visualizar guias

                .p-col-6.ta-right
                    Button(:label=" model.id ? 'Salvar' : 'Finalizar' " icon='jam jam-check' style='max-width:150px')
    div(v-else)
        Panel(header="Visualizar Pagamento finalizado")
            .p-grid.p-fluid.p-align-end
                .p-col-12.p-md-12
                    label.form-label Descrição:
                    InputText(v-model='$v.model.ds_pagamento.$model' :readonly='true')

                .p-col-12.p-md-4
                    label.form-label Estabelecimento:
                    InputText(v-model='model.nm_estabelecimento' :readonly='true')

                .p-col-6.p-md-4
                    label.form-label Data início:
                    InputText(v-model='model.dt_inicial_f' :readonly='true')

                .p-col-6.p-md-4
                    label.form-label Data fim:
                    InputText(v-model='model.dt_final_f' :readonly='true')

                .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ie_status_pagamento.$error }" :readonly='true')
                    label.form-label Status Pagamento:
                    Dropdown(v-model='$v.model.ie_status_pagamento.$model' :options='options.statusPagamentos' dataKey='status'
                        optionLabel='nameStatus' optionValue='status' placeholder='Selecione' :filter='options.statusPagamentos.length > 0' disabled)

                .p-col-6.p-md-4(:class="{'form-group--error': submitted && $v.model.dt_competencia.$error }")
                    label.form-label Data de competência:
                    Calendar( v-model='$v.model.dt_competencia.$model' dateFormat="mm/yy" :locale="$parent.ptbr" :manualInput='false'
                        :yearRange="`2020:${proximoAno}`" :yearNavigator='true' view='month' :readonly='true')

                .p-col-6.p-md-4(:class="{'form-group--error': submitted && $v.model.dt_pagamento.$error }")
                    label.form-label Data de pagamento:
                    Calendar(
                        v-if="$v.model.ie_status_pagamento.$model == 'P' && model.dt_pagamento"
                        v-model="$v.model.dt_pagamento.$model"
                        dateFormat="dd/mm/yy"
                        :locale="$parent.ptbr"
                        :manualInput='false'
                        :readonly='true'
                        )
                    Calendar(v-else-if="$v.model.ie_status_pagamento.$model != 'P' && !model.dt_pagamento"
                            v-model="model.dt_hoje"
                            dateFormat="dd/mm/yy"
                            :locale="$parent.ptbr"
                            :manualInput='false'
                            :readonly='true'
                        )
                    Calendar(v-else
                            v-model=null
                            dateFormat="dd/mm/yy"
                            :locale="$parent.ptbr"
                            :manualInput='false'
                            :readonly='true'
                        )

                .p-col-6.p-md-4
                    label.form-label Valor bruto:
                    InputText(v-model='model.nr_valorbruto_f' :readonly='true')

                .p-col-6.p-md-4
                    label.form-label Desconto INSS:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        span(style='flex:1')
                            currency-input.input-nr_descontoinss.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                v-model='model.nr_descontoinss'
                                locale='pt-BR'
                                :auto-decimal-mode='true'
                                :value-range='{ min: 0 }'
                                :readonly='true')
                                

                .p-col-6.p-md-4
                    label.form-label Imposto federal:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        span(style='flex:1')
                            currency-input.input-nr_impostofederal.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                v-model='model.nr_impostofederal'
                                locale='pt-BR'
                                :auto-decimal-mode='true'
                                :value-range='{ min: 0 }'
                                :readonly='true')

                .p-col-12.mt-2.mb-2(v-if="model.id")
                    .p-grid
                        .p-col-12.p-md-6.p-lg-3
                            span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                        .p-col-12.p-md-6.p-lg-3
                            span <b>Data da criação: </b> {{ model.dt_criado_f }}
                        .p-col-12.p-md-6.p-lg-3
                            span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                        .p-col-12.p-md-6.p-lg-3
                            span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                .p-col-12(v-if="this.model.guias_processadas.length > 0")
                    DataTable(:value="this.model.guias_processadas" responsiveLayout="scroll")
                        Column(field='nm_especialista' header='Especialista')
                        Column(field='valor_repasse' header='Valor Repasse' headerStyle='width: 10em')
                        Column(field='valor_total' header='Valor Total' headerStyle='width: 10em')

                .p-col-6
                    a(@click='$parent.finalizar = false' style='cursor:pointer') Visualizar guias
</template>
    
    <style lang='scss'>
    .faturamento-listar {
        .form-finalizar {
            .p-inputtext[readonly=readonly] {
                background-color: #eee;
                font-weight: 700;
            }
    
            .p-calendar > .p-inputtext {
                background-color: #fff;
                font-weight: 400;
            }
        }
    }
    </style>
    
    <script>
    import InputText from 'primevue/inputtext'
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import Calendar from 'primevue/calendar'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import ProgressSpinner from 'primevue/progressspinner'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Tooltip from 'primevue/tooltip'
    import {required} from 'vuelidate/lib/validators'
    import moment from 'moment'
    import {Bancos, Pagamentos, Relatorio} from '../../middleware'
    import wsConfigs from "../../middleware/configs";
    
    export default {
        created() {
            Bancos.findAll().then(response => {
                if (response.status == 200) this.options.bancos = response.data
                this.options.statusPagamentos = [{status: "E", nameStatus: "Enviado"}, {
                    status: "A",
                    nameStatus: "Faturado"
                }, {status: "P", nameStatus: "Pago"}]
            })
            setTimeout(() => {
                let el = document.querySelector('.input-nr_descontoinss');
                if (el) {
                    let _setCursorEnd = (ev) => {
                        if (ev.type == 'keydown' && ev.keyCode == 46) {
                            this.model.nr_descontoinss = 0
                        }
                        let pos = el.value.length;
                        el.setSelectionRange(pos, pos)
                    }
                    el.onclick = _setCursorEnd;
                    el.onkeydown = _setCursorEnd
                }
    
                el = document.querySelector('.input-nr_impostofederal');
                if (el) {
                    let _setCursorEnd = (ev) => {
                        if (ev.type == 'keydown' && ev.keyCode == 46) {
                            this.model.nr_impostofederal = 0
                        }
                        let pos = el.value.length;
                        el.setSelectionRange(pos, pos)
                    }
                    el.onclick = _setCursorEnd;
                    el.onkeydown = _setCursorEnd
                }
            })
        },
        components: {InputText, ProgressBar, Panel, Calendar, Button, Dropdown, ProgressSpinner,DataTable,Column,Tooltip},
        directives: { tooltip: Tooltip },
        watch: {
            '$parent.finalizar': function (finalizar) {
                if (finalizar) {
                    if (this.$parent.id && !this.model.id) {
                        this.waitingForm = true
                        Pagamentos.find(this.$parent.id).then(response => {
                            if (response.status === 200) {
                                this.model.id = response.data.id
                                this.model.ds_pagamento = response.data.ds_pagamento
                                this.model.nm_estabelecimento = response.data.cd_estabelecimento.nm_fantasia
                                this.model.ie_status_pagamento = response.data.ie_status_pagamento
                                this.model.cd_estabelecimento = response.data.cd_estabelecimento.id
                                this.model.dt_inicial = response.data.dt_inicial
                                this.model.dt_inicial_f = moment(response.data.dt_inicial).format('DD/MM/YYYY')
                                this.model.dt_final = response.data.dt_final
                                this.model.dt_final_f = moment(response.data.dt_final).format('DD/MM/YYYY')
                                this.model.dt_competencia = moment(response.data.dt_competencia)._d
                                this.model.dt_pagamento = moment(response.data.dt_pagamento)._d
                                this.model.cd_banco = response.data.cd_banco ? response.data.cd_banco.id : null
                                this.model.nr_agenciabancaria = response.data.nr_agenciabancaria
                                this.model.nr_contabancaria = response.data.nr_contabancaria
                                this.model.nr_operacaobancaria = response.data.nr_operacaobancaria
                                this.model.nr_valorbruto = parseFloat(response.data.nr_valorbruto)
                                this.model.nr_descontoinss = parseFloat(this.model.nr_descontoinss)
                                this.model.nr_impostofederal = parseFloat(this.model.nr_impostofederal)
                                this.model.nr_valorbruto_f = this.$parent.formatPrice(response.data.nr_valorbruto)
                                this.model.cd_guia_eletronica = response.data.guiaeletronica_set
                                this.model.nm_usuario_cri = response.data.nm_usuario_cri
                                this.model.nm_usuario_edi = response.data.nm_usuario_edi
                                this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                                this.model.dt_atualizado_f = response.data.dt_atualizado ?
                                    moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm') : null
    
                                this.$parent.filters.cd_estabelecimento = response.data.cd_estabelecimento.id
                                this.$parent.filters.dt_execucao_inicial = moment(response.data.dt_inicial)._d
                                this.$parent.filters.dt_execucao_final = moment(response.data.dt_final)._d
                                this.$parent.applyFilters(this.model.id).then(() => {
                                    this.$parent.selected = this.$parent.list.filter(guia => response.data.guiaeletronica_set.includes(guia.id_guia_eletronica))
                                    this.waitingForm = false
                                })
                                this.$parent.ie_status_pagamento = response.data.ie_status_pagamento
                                this.statusInicial = response.data.ie_status_pagamento
                            }
                        })
                        this.model.guias_processadas = this.$parent.selected;
                    } else {
                        this.model.dt_inicial_f = moment(this.$parent.filters.dt_execucao_inicial).format('DD/MM/YYYY')
                        this.model.dt_inicial = moment(this.$parent.filters.dt_execucao_inicial).format('YYYY-MM-DD')
                        this.model.dt_final_f = moment(this.$parent.filters.dt_execucao_final).format('DD/MM/YYYY')
                        this.model.dt_final = moment(this.$parent.filters.dt_execucao_final).format('YYYY-MM-DD')
                        this.model.cd_estabelecimento = this.$parent.filters.cd_estabelecimento
                        this.model.nm_estabelecimento = this.$parent.options.estabelecimentos.find(estabelecimento =>
                            estabelecimento.id == this.model.cd_estabelecimento).nm_fantasia
    
                        let sumGuias = 0
                        this.model.cd_guia_eletronica = []
                        this.$parent.selected.forEach(guia => {
                            this.model.cd_guia_eletronica.push(guia.id_guia_eletronica)
                            sumGuias += parseFloat(guia.valor_repasse)
                        })
                        this.model.nr_valorbruto = parseFloat(sumGuias.toFixed(2))
                        this.model.nr_valorbruto_f = this.$parent.formatPrice(this.model.nr_valorbruto)
    
                        if (!this.model.dt_competencia) this.model.dt_competencia = moment()._d
                        if (!this.model.dt_pagamento) this.model.dt_pagamento = moment()._d
    
                        this.model.guias_processadas = [];
                        this.$parent.selected.map(val=>{
                            const index = this.model.guias_processadas.findIndex(v => v.cd_especialista_id === val.cd_especialista_id);
                            if(index !== -1){
                                this.model.guias_processadas[index].valor_repasse += val.valor_repasse;
                                this.model.guias_processadas[index].valor_total += val.valor_total;
                                this.model.guias_processadas[index].cd_guia_eletronica.push(val.id_guia_eletronica);
                            }else{
                                this.model.guias_processadas.push({
                                    cd_especialista_id: val.cd_especialista_id,
                                    nm_especialista: val.nm_especialista,
                                    valor_repasse: val.valor_repasse,
                                    valor_total: val.valor_total,
                                    cd_guia_eletronica:[val.id_guia_eletronica],
                                })
                            }
                        });
    
                        console.log('especialidde', this.model.guias_processadas)
                        console.log(this.model.guias_processadas)
                        this.$forceUpdate()
                    }
    
                }
            }
        },
        data() {
            return {
                model: {
                    cd_estabelecimento: null,
                    dt_inicial: null,
                    dt_final: null,
                    ds_pagamento: null,
                    dt_competencia: null,
                    dt_pagamento: null,
                    cd_banco: null,
                    nr_agenciabancaria: null,
                    nr_contabancaria: null,
                    nr_operacaobancaria: null,
                    nr_valorbruto: null,
                    cd_guia_eletronica: [],
                    nr_descontoinss: 0,
                    nr_impostofederal: 0,
                    ie_status_pagamento: 'A',
                    guias_processadas: [],
                    dt_hoje: moment()._d
                },
                options: {
                    bancos: [],
                    statusPagamentos: []
                },
                statusInicial: null,
                waitingForm: false,
                waitingRelatorio: false,
                submitted: false,
                perPage: wsConfigs.paginator_perPage,
                proximoAno: parseInt(moment().format("YYYY")) + 1,
            }
        },
        validations() {
            let v = {
                model: {
                    ds_pagamento: {required},
                    dt_competencia: {required},
                    dt_pagamento: {required},
                    // cd_banco: { required },
                    // nr_agenciabancaria: { required },
                    // nr_contabancaria: { required },
                    // nr_operacaobancaria: { required },
                    nr_valorbruto: {required},
                    nr_descontoinss: {required},
                    nr_impostofederal: {required},
                    ie_status_pagamento: {required}
                }
            }
    
            return v
        },
        methods: {
            gerarRelatorio() {
                this.waitingRelatorio = true
                Relatorio.getRelatorioPagamento(this.model.id, 'pdf').then(response => {
                    this.waitingRelatorio = false
                    if (response.status == 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, {duration: 3000})
                        } else {
                            let blob = new Blob([response.data], {type: 'application/pdf; charset=utf-8'})
                            var fileURL = URL.createObjectURL(blob);
                            window.open(fileURL);
                        }
                    }
                })
            },
            handleSubmit() {
                this.submitted = true
    
                this.$v.$touch()
                if (this.$v.$invalid) return 0
    
                let dataSend = Object.assign({}, this.model)
    
                delete dataSend.nm_estabelecimento
                delete dataSend.dt_inicial_f
                delete dataSend.dt_final_f
                delete dataSend.nr_valorbruto_f
                delete dataSend.nr_valorbruto
    
                dataSend.dt_competencia = moment(dataSend.dt_competencia).format('MM/YYYY')
                dataSend.dt_pagamento = moment(dataSend.dt_pagamento).format('YYYY-MM-DD')
    
                console.log('----------------------------------------');
                console.log('guias', this.model.guias_processadas);
                console.log('----------------------------------------');
                console.log(dataSend);
    
                this.waitingForm = false
    
                Pagamentos.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! this.model.id) this.$router.push({ name: 'faturamento' })
                        this.$toast.success('Pagamento registrado com sucesso', { duration: 3000 })
                    } else if (([400]).includes(response.status)) {
                        if (response.data)
                            Object.values(response.data).forEach(error => {
                                error.forEach(errorMsg => this.$toast.error(errorMsg, { duration: 3000 }))
                            })
                    }
                    this.waitingForm = false
                })
            },
            getHoje() {
                return moment()._d
            }
        }
    }
    </script>
    