<template lang="pug">
	.main-wrapper.faturamento-listar
			header.main-wrapper-header
				.p-grid.p-align-center
					.p-col-3
						Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
					.p-col-9.ta-right
						h1.text-header.text-secondary Medclub /
							<router-link :to='{ name: "faturamento" }'> Faturamento</router-link> /
							<b>Guias</b>
	
			div(v-show='finalizar')
	
				Finalizar(ref='finalizar')
	
			div(v-show='!finalizar')
				Panel.filters(header='Selecione prestador e período' v-show='!waiting')
	
					.p-grid.p-fluid
	
						.p-col-12.p-md-6
							label.form-label Prestador:
							Dropdown(v-model='filters.cd_estabelecimento' :options='options.estabelecimentos' :filter='options.estabelecimentos.length > 0'
								optionLabel='nm_fantasia' optionValue='id' dataKey='id' :disabled='id != 0' placeholder='- Selecione -' @input='atualizaEspecialistas();applyFilters();')
	
						.p-col-12.p-md-3
							label.form-label Data inicial:
							Calendar(:class="{ active: id == 0 }" v-model='filters.dt_execucao_inicial' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_execucao_final'
								:manualInput='false' :touchUI='windowInnerWidth < 576' :readonly='id != 0' @input='applyFilters()' )
	
						.p-col-12.p-md-3
							label.form-label Data final:
							Calendar(:class="{ active: id == 0 }" v-model='filters.dt_execucao_final' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='filters.dt_execucao_inicial'
								:manualInput='false' :touchUI='windowInnerWidth < 576' :readonly='id != 0' @input='applyFilters()' )
	
						.p-col-12.p-md-6(v-show='id == 0')
							label.form-label Especialista:
							ProgressBar(v-if='waiting_especialistas' mode="indeterminate")
							Dropdown(v-model='filters.cd_especialista' v-if='!waiting_especialistas' :options='options.especialistas' :filter='options.especialistas.length > 0'
								optionLabel='text' optionValue='value' dataKey='value' placeholder='TODOS' @input='applyFilters()')
	
						.p-col-12.p-md-6(v-show='id == 0')
							label.form-label Tipo de agenda:
							Dropdown(v-model='filters.ie_tipo_agenda' :options='options.tipo_agenda'
								optionLabel='label' optionValue='key' dataKey='key' placeholder='TODOS' @input='applyFilters()')
	
				.mt-2
	
					ProgressBar(v-if='waiting' mode="indeterminate")
					div(v-else-if='! filters.cd_estabelecimento')
						p.ta-center.text-secondary(style='margin-top: 40px;') Por favor, selecione um estabelecimento.
					div(v-else-if='list.length == 0')
						p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
					div(v-else)
						Panel.datatable(header='Lista de guias' v-if="ie_status_pagamento!='P'")
							DataTable(:value="list" :filters="filters_table" :paginator='true' :rows='perPage' dataKey='id' :selection.sync="selected" :metaKeySelection='false')
								Column(selectionMode='multiple' headerStyle='width: 3em')
								Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='cd_transacao_financeira' filterMatchMode="contains" header='Cód. guia' sortable)
									template(#filter)
										InputText(type="text" v-model="filters_table['cd_transacao_financeira']" class="p-column-filter" placeholder="Guia")
	
								Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_execucao_f' header='Dt. Exec' sortable)
									template(#filter)
										InputText(type="text" v-model="filters_table['dt_execucao_f']" class="p-column-filter" placeholder="Data")
	
								Column(headerStyle='width: 25%;' field='nm_paciente' header='Nome do paciente' sortable)
									template(#filter)
										InputText(type="text" v-model="filters_table['nm_paciente']" class="p-column-filter" placeholder="Nome do Paciente")
								Column(headerStyle='width: 25%;' field='nm_procedimento_filter' filterMatchMode="contains" sortable)
									template(#header) Procedimento/Profissional
									template(#body='props')
										span {{ props.data.nm_procedimento }}
										span(v-if='props.data.nm_especialista') <br><b>Dr(a). {{ props.data.nm_especialista }} ({{ props.data.nm_conselho }} {{ props.data.nr_conselho }})</b>
									template(#filter)
										InputText(type="text" v-model="filters_table['nm_procedimento_filter']" class="p-column-filter" placeholder="Procedimento")
								Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='valor_repasse_f' header='Repasse')
								Column(headerStyle='width: 10%;' bodyStyle='padding: 0;' header='Situação')
									template(#body='props')
										.cell(:class="{\
											ex: props.data.nm_situacao == 'EXECUTADA',\
											a: props.data.nm_situacao == 'EMITIDA',\
											c: props.data.nm_situacao == 'CANCELADA'\
										}") {{ props.data.nm_situacao }}
								Column(headerStyle='width: 10%;' header='Tipo' bodyStyle='text-align: center;' field='ie_tipo_agenda' filterMatchMode="contains" sortable)
									template(#body='props')
										span {{ props.data.ie_tipo_agenda == 'E' ? 'Exame' : 'Consulta' }}
									template(#filter)
										InputText(type="text" v-model="filters_table['ie_tipo_agenda_f']" class="p-column-filter" placeholder="Tipo")
						
						Panel.datatable(header='Lista de guias' v-else)
							DataTable(:value="list" :filters="filters_table" :paginator='true' :rows='perPage' dataKey='id' :metaKeySelection='false')
								Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='cd_transacao_financeira' filterMatchMode="contains" header='Cód. guia')
									template(#filter)
										InputText(type="text" v-model="filters_table['cd_transacao_financeira']" class="p-column-filter" placeholder="Guia")
	
								Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_execucao_f' header='Dt. Exec')
									template(#filter)
										InputText(type="text" v-model="filters_table['dt_execucao_f']" class="p-column-filter" placeholder="Data")
	
								Column(headerStyle='width: 25%;' field='nm_paciente' header='Nome do paciente')
									template(#filter)
										InputText(type="text" v-model="filters_table['nm_paciente']" class="p-column-filter" placeholder="Nome do Paciente")
								Column(headerStyle='width: 25%;' field='nm_procedimento_filter' filterMatchMode="contains")
									template(#header) Procedimento/Profissional
									template(#body='props')
										span {{ props.data.nm_procedimento }}
										span(v-if='props.data.nm_especialista') <br><b>Dr(a). {{ props.data.nm_especialista }} ({{ props.data.nm_conselho }} {{ props.data.nr_conselho }})</b>
									template(#filter)
										InputText(type="text" v-model="filters_table['nm_procedimento_filter']" class="p-column-filter" placeholder="Procedimento")
								Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='valor_repasse_f' header='Repasse')
								Column(headerStyle='width: 10%;' bodyStyle='padding: 0;' header='Situação')
									template(#body='props')
										.cell(:class="{\
											ex: props.data.nm_situacao == 'EXECUTADA',\
											a: props.data.nm_situacao == 'EMITIDA',\
											c: props.data.nm_situacao == 'CANCELADA'\
										}") {{ props.data.nm_situacao }}
								Column(headerStyle='width: 10%;' header='Tipo' bodyStyle='text-align: center;' field='ie_tipo_agenda_f' filterMatchMode="contains")
									template(#body='props')
										span {{ props.data.ie_tipo_agenda == 'E' ? 'Exame' : 'Consulta' }}
									template(#filter)
										InputText(type="text" v-model="filters_table['ie_tipo_agenda_f']" class="p-column-filter" placeholder="Tipo")
	
						.ta-right.mt-4(v-if="selected.length>0 && ie_status_pagamento!='P'")
							Button(:label="`${ id != 0 ? 'Atualizar' : 'Cadastrar' } pagamento`" :icon="id != 0 ? 'jam jam-check' : 'jam jam-plus'" @click="finalizarFaturamento()")
</template>
	
	<style lang="scss">
		.faturamento-listar {
			.datatable {
				@media all and (max-width: 576px) {
					display: none;
				}
				.cell {
					padding: 10px 0;
					text-align: center;
					font-weight: 700;
				}
				.p-highlight {
					.cell { background-color: #007ad9 !important; }
				}
			}
			.filters {
				.p-disabled {
					opacity: 1;
				}
				.p-inputtext[readonly=readonly], .p-disabled .p-inputtext, .p-disabled .p-dropdown-trigger {
					color: #333 !important;
					background-color: #eee;
					font-weight: 700;
				}
				.p-calendar.active > .p-inputtext {
					background-color: #fff;
					font-weight: 400;
				}
				.p-disabled .p-dropdown-trigger {
					color: #eee !important;
				}
			}
		}
	</style>
	
	<script>
		import Panel from 'primevue/panel'
		import Dropdown from 'primevue/dropdown'
		import Calendar from 'primevue/calendar'
		import ProgressBar from 'primevue/progressbar'
		import DataTable from 'primevue/datatable'
		import Column from 'primevue/column'
		import Button from 'primevue/button'
		import InputText from 'primevue/inputtext'
	
	
		import Finalizar from './ListarGuias-finalizar'
		import { Estabelecimento, GuiaEletronica, Agenda } from '../../middleware'
		import { pCalendarLocale_ptbr, fixTelefoneFormat } from './../../utils'
		import moment from 'moment'
		import wsConfigs from './../../middleware/configs'
	
		export default {
			components: { Panel, Dropdown, InputText, Calendar, ProgressBar, DataTable, Column, Button, Finalizar },
			created () {
				this.waiting = true
				this.waiting_especialistas = true
				Agenda.getEspecialistasAgendados().then(response => {
					this.waiting_especialistas = false
					if (response.status == 200) {
						let mem = {}
						response.data.forEach(e => {
							if (! mem[e.cd_especialista]) {
								mem[e.cd_especialista] = true
								this.options.especialistas.push({ text: e.nm_especialista, value: e.cd_especialista })
							}
						})
						this.options.especialistas.sort((a, b) => a.text > b.text ? 1 : -1)
						this.options.especialistas.unshift({ text: 'TODOS', value: null })
					}
					Estabelecimento.findAll().then(response => {
						this.waiting = false
						if (response.status == 200) this.options.estabelecimentos = response.data
	
						if (! isNaN(this.$route.params.id)) this.id = parseInt(this.$route.params.id)
						if (this.id != 0) this.finalizar = true
					})
				})
			},
			data () {
				return {
					id: 0,
					ptbr: pCalendarLocale_ptbr,
					windowInnerWidth: window.innerWidth,
					list: [],
					selected: [],
					filters_table: {},
					filters: {
						cd_estabelecimento: null,
						dt_execucao_inicial: moment()._d,
						dt_execucao_final: moment()._d,
						cd_guia_situacao: 5,
						cd_pagamento: null,
						ie_tipo_agenda: null,
						cd_especialista: null,
						ie_sem_estorno: true
					},
					options: {
						estabelecimentos: [],
						especialistas: [],
						tipo_agenda: [
							{ key: null, label: 'TODOS' },
							{ key: 'C', label: 'Consulta' },
							{ key: 'E', label: 'Exame' }
						]
					},
					waiting: false,
					waiting_especialistas: false,
					perPage: wsConfigs.paginator_perPage,
					finalizar: false,
					ie_status_pagamento: null
				}
			},
			methods: {
				filterDate(value, filter) {
					if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
						return true;
					}
	
					if (value === undefined || value === null) {
						return false;
					}
	
					return value === this.formatDate(filter);
				},
				formatDate(date) {
					let month = date.getMonth() + 1;
					let day = date.getDate();
	
					if (month < 10) {
						month = '0' + month;
					}
	
					if (day < 10) {
						day = '0' + day;
					}
	
					return date.getFullYear() + '-' + month + '-' + day
				},
				formatPrice (val) {
					return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
				},
				atualizaEspecialistas(){
					this.options.especialistas = []
					this.waiting_especialistas = true
					Agenda.getEspecialistasAgendados({cd_estabelecimento:this.filters.cd_estabelecimento}).then(response => {
						this.waiting_especialistas = false
						if (response.status == 200) {
							console.log(response)
							let mem = {}
							response.data.forEach(e => {
								if (! mem[e.cd_especialista]) {
									mem[e.cd_especialista] = true
									this.options.especialistas.push({ text: e.nm_especialista, value: e.cd_especialista })
								}
							})
							this.options.especialistas.sort((a, b) => a.text > b.text ? 1 : -1)
							this.options.especialistas.unshift({ text: 'TODOS', value: null })
						}
					})
				},
				applyFilters (cd_pagamento=null) {
	
					if (cd_pagamento) this.filters.cd_pagamento = cd_pagamento
	
					if (! this.filters.cd_estabelecimento) {
						this.list = []
						return 0
					}
	
					let params = {}
					Object.keys(this.filters).forEach((key) => {
						if (key.substr(0, 3) == 'dt_') {
							if (moment(this.filters[key], 'DD/MM/YYYY').isValid())
							params[key] = moment(this.filters[key], 'DD/MM/YYYY').format('YYYY-MM-DD')
						} else if (this.filters[key]) params[key] = this.filters[key]
					})
					return this.getList(params)
				},
				getList (params) {
					this.waiting = true
					return GuiaEletronica.findAll(params, { ignorarEstabelecimento: true }).then(response => {
						if (response.status == 200) {
							response.data.forEach(entry => {
								if (entry.dt_agenda) entry.dt_agenda = moment(entry.dt_agenda).format('DD/MM/YYYY')
								entry.dt_execucao_f = moment(entry.dt_execucao).format('DD/MM/YYYY')
								entry.nm_paciente_f = (entry.nm_paciente == null) ? entry.nm_paciente : entry.nm_paciente.split(" ")[0]
								entry.ie_tipo_agenda_f = (entry.ie_tipo_agenda_f == 'E') ? 'EXAME' : 'CONSULTA'
								entry.nm_procedimento_filter = `${entry.nm_procedimento} ${entry.nm_especialista || ''}`
	
								if (entry.hr_agenda) {
									let aux = entry.hr_agenda.split(':')
									entry.hr_agenda_f = `${ aux[0] }:${ aux[1] }`
								}
								entry.valor_repasse_f = this.formatPrice(entry.valor_repasse)
								if (entry.nr_telefone_paciente) entry.nr_telefone_paciente_f = fixTelefoneFormat(entry.nr_telefone_paciente)
							})
							this.list = response.data
							// if (! this.filters.cd_pagamento) {
							// 	window.setTimeout(() => {
							// 		let input = document.querySelector('.p-datatable-thead .p-checkbox input')
							// 		if (input) if (! input.checked) input.click()
							// 	})
							// }
						}
						this.waiting = false
						return 0
					})
				},
				onPage (ev) {
					this.paginator.page = ev.page + 1
					this.applyFilters()
				},
				finalizarFaturamento () {
					this.finalizar = true
				}
			}
		}
	</script>
	